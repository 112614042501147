<template>
  <div class="iss-massdata">
    <div class="massdata-wrapper">
      <div class="statistics-card mb-16">
        <div class="flex" style="justify-content: space-between;">
         <div class="flex"> 
          <div class="mr-10 fontStyle" style="margin-bottom: 0;" >发送概况</div>
          <div  >
            <a-space :size="16">
              <a-button
                :class="dayButton === 'day' ? '' : 'time-btn'"
                :type="dayButton === 'day' && 'primary'"
                @click="handleFnCilckDay('day')"
                >近7天</a-button
              >
              <a-button
                :class="dayButton === 'month' ? '' : 'time-btn'"
                :type="dayButton === 'month' && 'primary'"
                @click="handleFnCilckDay('month')"
                >近一个月天
              </a-button>
              <a-button
                :class="dayButton === 'year' ? '' : 'time-btn'"
                :type="dayButton === 'year' && 'primary'"
                @click="handleFnCilckDay('year')"
                >近半年
              </a-button>
            </a-space>
          </div>
         </div>
         <a href="jvoid(0)" class="fontStyle" style="color:#333;text-decoration: underline;"> 关联公众号</a>
        </div>
        <a-row style="text-align: center" class="mt-42">
          <a-col :span="6">
            <p>粉丝总数</p>
            <p class="num">{{ sendMessageData.fansCount }}</p>
          </a-col>
          <a-col :span="6">
            <p>发送总数</p>
            <p class="num">{{ sendMessageData.sendTotalCount }}</p>
          </a-col>
          <a-col :span="6">
            <p>送达成功总数</p>
            <p class="num">{{ sendMessageData.sendTotalSuccessCount }}</p>
          </a-col>
          <a-col :span="6">
            <p>点击打开数</p>
            <p class="num">{{ sendMessageData.openTemplateCount }}</p>
          </a-col>
        </a-row>
      </div>
      <a-row :gutter="16" class="mb-16">
        <a-col :span="12">
       
          <div class="graph-card">
            <div class="fs-16 fontStyle">用户点击数据</div>
           <a-row type="flex" style="position: relative;">
            <a-col :span="18"> <a-space :size="16">
              <a-button
                :class="clickButton === 'day' ? '' : 'time-btn'"
                :type="clickButton === 'day' && 'primary'"
                @click="handleFnCilckBtn('day')"
                >近7天</a-button
              >
              <a-button
                :class="clickButton === 'month' ? '' : 'time-btn'"
                :type="clickButton === 'month' && 'primary'"
                @click="handleFnCilckBtn('month')"
                >近一个月天
              </a-button>
              <a-button
                :class="clickButton === 'year' ? '' : 'time-btn'"
                :type="clickButton === 'year' && 'primary'"
                @click="handleFnCilckBtn('year')"
                >近半年
              </a-button>
            </a-space></a-col>
            <a-col class="card-tip">
                <div class="flex"><div class="line"></div>
                <span>用户点击次数</span></div>
            </a-col>
           </a-row>
        <a-row>
          <div ref="chartContainer" style="width: 100%; min-height: 500px;" />
        </a-row>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="table-card">
            <div class="fs-16 fontStyle">近期群发</div>
            <div class="record-list">
              <a-table
                class="iss-grid"
                v-bind="$attrs"
                size="middle"
                :columns="recordColumns"
                :data-source="recordList"
                :pagination="recordPanigation"
                :loading="recordLoading"
              >
                <template #fanType="{ text }">
                  <span v-if="text === 0">全部粉丝</span>
                  <span v-if="text === 1">标签粉丝</span>
                </template>
                <template #sendTime="{ text }">
                  <span v-if="!text">-</span>
                  <span v-else>{{ text }}</span>
                </template>
                <template #sendCount="{ text }">
                  <span v-if="!text">-</span>
                  <span v-else>{{ text }}</span>
                </template>
                <template #successCount="{ text }">
                  <span v-if="!text">-</span>
                  <span v-else>{{ text }}</span>
                </template>
                <template #sendStatus="{ text }">
                  <span v-if="text === 0">待发送</span>
                  <span v-if="text === 1">发送中</span>
                  <span v-if="text === 2">发送完成</span>
                </template>
                <template #successRate="{ record }">
                  <span v-if="record.successCount == 0 || record.sendCount == 0"
                    >0%</span
                  >
                  <span v-else-if="!record.successCount || !record.sendCount"
                    >-</span
                  >
                  <span v-else>
                    {{
                      (record.successCount / record.sendCount).toFixed(2) * 100
                    }}%
                  </span>
                </template>
                <template #operation="{ record }">
                 <img src="../../../assets/images/Signal-3.png" alt="" class="ico-btn" @click="checkDetail(record)">
                </template>
              </a-table>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { Col, Row, Button, Space, Table } from 'ant-design-vue';
import { reactive, toRefs, onMounted, onUnmounted, ref } from 'vue';
import * as echarts from 'echarts';
import wechatApi from '@/api/wechatServer';
import { useStore } from 'vuex';
import moment from 'moment';
import { useRouter } from 'vue-router';
export default {
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,

    ASpace: Space,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      appId: store.state.account.appId,
      dayButton: 'day',
      clickButton:'day',
      // xAxisData: [
      //   '2023-05-16',
      //   '2023-05-17',
      //   '2023-05-18',
      //   '2023-05-19',
      //   '2023-05-20',
      //   '2023-05-21',
      //   '2023-05-22',
      // ],
      // yAxisData: [600, 300, 500, 100, 200, 735.31, 235.31],
      xAxisData: [],
      yAxisData: [],
      sendMessageData: {
        fansCount: '',
        openTemplateCount: '',
        sendTotalCount: '',
        sendTotalSuccessCount: '',
      },
      // echartsData: {},
      recordPanigation: {
        current: 1,
        size: 10,
        total: 10,
      },
      recordList: [
        // {
        //   fanType: 0,
        //   sendTimeType: 0,
        //   sendStatus: 2,
        // },
        // {
        //   fanType: 0,
        //   sendTimeType: 0,
        //   sendStatus: 1,
        // },
        // {
        //   fanType: 1,
        //   sendTimeType: 1,
        //   sendStatus: 1,
        // },
        // {
        //   fanType: 0,
        //   sendTimeType: 1,
        //   sendStatus: 0,
        // },
      ],
    });

    // const startIndex = ref(1);
    // const endIndex = ref(3);
    const chartContainer = ref(null);
    let chartInstance = null;
 
    const computeDate = (type = 'day') => {
      let now = moment();
      let beginTime = '';
      let sevenDaysAgo = null;
      let oneMonthAgo = null;
      let sixMonthsAgo = null;

        let endTime = now.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      switch (type) {
        case 'day':
          sevenDaysAgo = now.clone().subtract(6, 'days');
          beginTime = sevenDaysAgo.startOf('day').format('YYYY-MM-DD HH:mm:ss');
          break;
        case 'month':
          oneMonthAgo = now.clone().subtract(29, 'days');
          beginTime = oneMonthAgo.startOf('day').format('YYYY-MM-DD HH:mm:ss');
          break;
        case 'year':
          sixMonthsAgo = moment().subtract(6, 'month');
          beginTime = sixMonthsAgo.startOf('month').format('YYYY-MM-DD HH:mm:ss');
          endTime =now.endOf('month').format('YYYY-MM-DD HH:mm:ss');
          break;
      }
    
      return {
        beginTime,
        endTime
      };
    };
    const getSendMessageData = (type = 'day') => {
      let { beginTime, endTime } = computeDate(type);
      let data = {
        appId: state.appId,
        type: type,
        beginTime,
        endTime
      };
      wechatApi.sendMessageTemplateCount({}, data).then(res => {
        if (res) {
          state.sendMessageData = res;
        }
      });
    };
    const getOpenClickData = (type = 'day') => {
      let { beginTime, endTime } = computeDate(type);
      let data = {
        appId: state.appId,
        type: type,
        beginTime,
        endTime
      };
    
      wechatApi.contactOpenClickCount({}, data).then(res => {
        if (res) {

          state.xAxisData = Object.keys(res);
          state.yAxisData = Object.values(res);
          // 配置项
          const option = {
          
            tooltip: {
              trigger: 'axis',

              axisPointer: {
                type: 'cross',
              },
            },
            grid: {
              left: '5%',
              right: '15%',
              bottom: '10%',
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: state.xAxisData,
                interval: 0, // 不自动隐藏任何标签
                axisTick: {
          alignWithLabel: true,
        },
        
              },
            ],
            yAxis: [
              {
                type: 'value',
              },
            ],

            series: [
              {
                name: '用户点击次数',
                type: 'line',
                stack: '总量',
                smooth: true,
                lineStyle: {
                  width: 0,
                },
                showSymbol: false,
                data: state.yAxisData,
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: 'rgb(229, 242, 254)',
                    },
                    {
                      offset: 1,
                      color: 'rgb(229, 242, 254)',
                    },
                  ]),
                },
                emphasis: {
                  focus: 'series',
                },
              },
            ],
          };
          // 设置图表配置项
          chartInstance.setOption(option);
        }
      });
    };
       // 获取模版消息记录
       const getWechatMessageRecordsListFun =() => {
      let data = {
        listType: 0,
        ...state.recordPanigation,
        appId:state.appId
      };
      wechatApi.getWechatMessageRecordsList({}, data).then(res => {
        if (!res) {
          console.log(res);
        } else {
          state.recordPanigation.total = res.total;
          state.recordList = res.records;
        }
      });
    };
    const _init = async () => {
      // 初始化图表实例
      chartInstance = echarts.init(chartContainer.value);
      // await wechatApi.getTenantId().then(res => {
      //   if (!res) {
      //     console.log(res);
      //   } else {
      //     state.appId = res.appId[0].appId;
      //     store.commit('account/setAppId', res.appId[0].appId);
      //   }
      // });
      getSendMessageData('day');
      getOpenClickData('day');
      getWechatMessageRecordsListFun()
    };
    onMounted(async () => {
      _init();
    });

    onUnmounted(() => {
      // 清理工作，释放图表实例
      if (chartInstance) {
        chartInstance.dispose();
        chartInstance = null;
      }
    });
    return {
      chartContainer,
      ...toRefs(state),
      handleFnCilckDay(time) {
        state.dayButton = time;
        getSendMessageData(time);
      },
      handleFnCilckBtn(time) {
        state.clickButton = time;
        getOpenClickData(time);
      },
       checkDetail(record) {
      router.push({
        name: 'massDataDetail',
        params: {
          id: record.id,
          sendTemplateId: record.sendTemplateId,
        },
      });
    },
      recordColumns: [
        {
          dataIndex: 'templateName',
          title: '名称',
          width: 140,
          ellipsis: true,
          slots: { customRender: 'templateName' },
        },
        {
          dataIndex: 'fanType',
          title: '发送对象',
          width: 80,
          ellipsis: true,
          slots: { customRender: 'fanType' },
        },
        {
          dataIndex: 'sendTime',
          title: '发送时间',
          width: 280,
          ellipsis: true,
          slots: { customRender: 'sendTime' },
        },

        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.iss-massdata {
  padding: 16px;
  .massdata-wrapper {
    padding: 10px;
    margin: -10px;
    height: calc(100vh - 68px);
    overflow-y: auto;
  }
}
.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
}
.graph-card,
.table-card {
height: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
}
.num {
  color: #5979f8;
  font-size: 28px;
  font-weight: 600;
}
.line{
  width: 22px;
height: 0px;
border: 2px solid #4FA8F9;
border-radius: 9px;
}
.ico-btn{
  width: 18px;
  height: 18px;
}
.card-tip{
  position: absolute;
  right: 0;
  top: 0;
}
.fontStyle {
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313d5f;
  line-height: 24px;
  margin-bottom: 26px;
}
.time-btn {
  border-radius: 99px;
  background: #d6d6d6;
  color: #666666;
  border-color: transparent;
}
:deep(.ant-btn-primary) {
  border-radius: 99px;
}
.record-list{
  :deep(.ant-pagination){
    display: none;
  }
}

</style>

